<template>
  <el-container style="height: 100vh; border: 1px solid #eee">
    <el-aside style="background-color: rgb(255, 255, 255);width: unset;">
      <div v-if="!isCollapse" class="header">
        <div>
          <img style="width: 180px;padding: 30px;" src="../assets/img/shop/header-icon.png" alt="">
        </div>
      </div>
      <product-menu :is-collapse="isCollapse" />
    </el-aside>

    <el-container>
      <el-header
        style="text-align: right; font-size: 12px; padding: 0; height: unset"
      >
        <heard :is-collapse="isCollapse" />
      </el-header>
      <el-main style="padding: 0">
        <keep-alive include="Searchproduct">
          <router-view />
        </keep-alive>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import heard from '@/productlayout/components/heard.vue'
import productMenu from './components/menu.vue'
export default {
  components: {
    heard,
    productMenu
  },
  provide() {
    return {
      collapseMenu: this.collapseMenu
    }
  },
  data() {
    return {
      isCollapse: false
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    // 屏幕检测
    init() {
      this.setScreen()
      window.onresize = () => {
        setTimeout(() => {
          this.setScreen()
        }, 0)
      }
    },
    setScreen() {
      const screenNumber = this.getScreen()
      if (screenNumber === 3) {
        this.isCollapse = false
      } else {
        this.isCollapse = true
      }
    },

    getScreen: function() {
      var width = document.body.clientWidth
      if (width >= 1200) {
        return 3 // 大屏幕
      } else if (width >= 992) {
        return 2 // 中屏幕
      } else if (width >= 768) {
        return 1 // 小屏幕
      } else {
        return 0 // 超小屏幕
      }
    },
    collapseMenu() {
      this.isCollapse = !this.isCollapse
    }
  }
}
</script>
<style lang="scss" scoped>

.el-header {
  background-color: #b3c0d1;
  color: #333;
}

.el-aside {
  color: #333;
}
.menu {
  text-align: left;
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 91px;
  text-align: center;
  line-height: 91px;
}
</style>
