/**
* @author  XuHongli
* @date  2022/2/17 13:58
* @version 1.0
* @description 客服组件
*/
<template>
  <div :class="newwindows?'nWindow':''">
    <div :style="newwindows?'margin-top:10px':''" class="offline" style="flex: 1;">
      <p style="text-align: left;">您好 ，客服现在不在线，如有需要请留言。我们将尽快联系您</p>
      <el-form
        ref="offForm"
        :style="newwindows?'margin-top:14px':''"
        :rules="rules"
        label-position="top"
        label-width="80px"
        :model="formLabelAlign"
      >
        <el-form-item label="姓名" prop="name">
          <el-input v-model="formLabelAlign.name" class="off-input" />
        </el-form-item>
        <el-form-item label="手机" prop="phone">
          <el-input v-model="formLabelAlign.phone" class="off-input" />
        </el-form-item>
        <el-form-item label="公司" prop="company">
          <el-input v-model="formLabelAlign.company" class="off-input" />
        </el-form-item>
        <el-form-item label="留言内容" prop="content">
          <el-input
            v-model="formLabelAlign.content"
            class="off-input"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 5 }"
          />
        </el-form-item>
      </el-form>
      <el-button class="commit" @click="commitOffForm()">
        提交留言
      </el-button>
    </div>
    <div v-show="newwindows">
      <img class="showpic" src="../../assets/img/chat/left.png" alt="">
    </div>
  </div>
</template>

<script>
import { isvalidPhone } from '@/utils/validate'
import { addLeaveWord } from '@/api/register'

export default {
  props: {
    newwindows: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入电话号码'))
      } else if (!isvalidPhone(value)) {
        callback(new Error('请输入正确的11位手机号码'))
      } else {
        callback()
      }
    }
    return {
      formLabelAlign: {},
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        phone: [{ required: true, trigger: 'blur', validator: validPhone }],
        company: [
          {
            required: true,
            message: '请输入公司名称',
            trigger: 'blur'
          }
        ],
        content: [
          {
            required: true,
            message: '请输入留言内容',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    commitOffForm() {
      this.$refs.offForm.validate((valid) => {
        if (valid) {
          addLeaveWord(this.formLabelAlign).then((result) => {
            if (result.code === 0) {
              this.$message.success('留言成功')
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.nWindow{
  display: flex;
}
.offline {
  margin-right: 6%;
  margin-left: 6%;
  display: flex;
  flex-direction: column;
  .commit {
    color: #ffffff;
    background: #152243;
    font-size: 12px;
  }
  .off-input {
    border: 0.6px solid #758cb4;
  }
}

::v-deep .el-form--label-top .el-form-item__label {
  padding: 0;
  float: left;
}
::v-deep .el-form-item__label {
  font-size: unset;
  line-height: unset;
}
::v-deep .el-form-item__content{
  line-height: unset;
}
::v-deep .el-form-item {
  margin-bottom: 22px;
}
::v-deep .el-input__inner {
  height: 24px;
}
</style>

<!-- 处理手机端适配 -->
<style lang="scss" scoped>
//  width:320px-479px
@media only screen  and (max-width:600px) {
  .showpic{
    width: 0px;
  }
}
</style>
