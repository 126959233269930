<!-- 规则中心 footer -->
<template>
  <a-row type="flex" justify="center">
    <a-col :lg="18" :span="23">
      <hr>
      <div class="footer">
        <div>
          <a href="/treaty/userAgreement">用户协议</a> |
          <a href="/treaty/privacyPolicy">隐私政策</a>
        </div>
        <div>
          <span>© 2022 深圳市万鲸科技有限公司 版权所有</span> |
          <span>粤ICP备20008713号-7</span>
        </div>
      </div>
    </a-col>
  </a-row>
</template>

<script>
export default {}
</script>
<style scoped>
.footer{
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 24px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: hsla(0,0%,65.9%,.7);
}
@media only screen and (min-width:0px) and (max-width:767px){
  .footer{
    justify-content: center;
    flex-direction: column;
  }
}
</style>

