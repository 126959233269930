<!-- 规则中心通用布局 -->
<template>
  <div class="ruleLayout">
    <header>
      <Navbar/>
    </header>
    <main style="min-height: 80vh">
      <router-view />
    </main>
    <footer>
      <Footer/>
    </footer>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/2/17 13:58
 * @version 1.0
 * @description 规则中心通用布局
 */
import Navbar from './Navbar'
import Footer from './footer'

export default {
  components: { Navbar, Footer }
}
</script>

<style lang="scss" scoped>
header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  z-index: 999;
}
</style>

