/**
 * @author  XuHongli
 * @date  2022/2/17 13:58
 * @version 1.0
 * @description  Vue Router 路由模块
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/Layout'
import productlayout from '@/productlayout/productlayout'
import ruleLayout from '@/ruleLayout/Layout'
import shopLayout from 'views/shop/Layout/shopLayout'
import NProgress from 'nprogress'
import store from '../store'
import 'nprogress/nprogress.css' // progress bar style
import { getToken, getusernameToken, removeToken, setusernameToken } from '../utils/cookie'

Vue.use(VueRouter)

// 清除Router错误报告
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/home',
    redirect: '/home',
    component: Layout,
    meta: { title: 'home' },
    children: [
      {
        name: 'Home',
        path: '/home',
        component: () => import('@/views/home/Home.vue'),
        meta: { title: 'home' },
        hidden: true
      },
      {
        name: 'Template',
        path: '/template',
        component: () => import('@/views/template'),
        meta: { title: 'template' },
        hidden: true
      }
    ]
  },
  {
    path: '/business/',
    redirect: '/business/module',
    component: Layout,
    meta: { title: 'business' },
    children: [
      {
        name: 'BusinessTemplate',
        path: '/Business/template',
        meta: { title: 'template' },
        redirect: '/template'
      },
      {
        name: 'BusinessModule',
        path: '/business/module',
        component: () => import('@/views/b2c/module/Module'),
        meta: { title: 'module' }
      },
      {
        name: 'BusinessCustomise',
        path: '/business/customise',
        component: () => import('@/views/b2c/customise/Customise'),
        meta: { title: 'customise' }
      }
    ]
  },
  {
    path: '/price',
    redirect: '/price',
    component: Layout,
    meta: { title: 'price' },
    children: [
      {
        path: '/price',
        name: 'Price',
        component: () => import('@/views/price/price')
      }
    ]
  },
  {
    name: 'case',
    path: '/case',
    component: Layout,
    meta: { title: 'case' },
    children: [
      {
        name: 'case',
        path: '/case',
        component: () => import('@/views/case/index'),
        meta: { title: 'case' },
        hidden: true
      }
    ],
    hidden: false
  },
  {
    path: '/Oversea',
    redirect: '/oversea',
    component: Layout,
    meta: { title: 'oversea' },
    children: [
      {
        name: 'Oversea',
        path: '/oversea',
        component: () => import('@/views/support/oversea/oversea'),
        meta: { title: 'support' },
        hidden: true
      }
    ],
    hidden: false
  },
  {
    path: '/CouldPhone',
    redirect: '/CouldPhone',
    component: Layout,
    meta: { title: 'CouldPhone' },
    children: [
      {
        name: 'CouldPhone',
        path: '/CouldPhone',
        component: () => import('@/views/cloudphone'),
        meta: { title: 'support' },
        hidden: true
      }
    ],
    hidden: false
  },
  {
    path: '/aboutUs',
    component: Layout,
    meta: { title: 'about' },
    children: [
      {
        name: 'aboutUs',
        path: '/aboutUs',
        component: () => import('@/views/about/aboutUs'),
        meta: { title: 'aboutUs' }
      },
      {
        name: 'contactUs',
        path: '/contactUs',
        component: () => import('@/views/about/contactUs'),
        meta: { title: 'contactUs' }
      }
    ]
  },
  {
    name: 'Register',
    path: '/register',
    component: () => import('@/views/register/Register'),
    hidden: true
  },
  {
    name: 'Rule',
    path: '/rule',
    redirect: '/RuleHome',
    component: ruleLayout,
    hidden: true,
    meta: { title: 'sdd' },
    children: [
      {
        name: 'RuleHome',
        path: '/RuleHome',
        component: () => import('@/views/rule/index')
      },
      {
        name: 'RuleCenter',
        path: '/ruleCenter',
        component: () => import('@/views/rule/rule')
      },
      {
        name: 'ShopFAQ',
        path: '/notice/ShopFAQ',
        component: () => import('@/views/rule/notice/ShopFAQ')
      },
      {
        name: 'ReleaseSpecialProducts',
        path: '/notice/ReleaseSpecialProducts',
        component: () => import('@/views/rule/notice/ReleaseSpecialProducts')
      },
      {
        name: 'ReturnGoods',
        path: '/notice/ReturnGoods',
        component: () => import('@/views/rule/notice/ReturnGoods')
      },
      {
        name: 'IntellectualProperty',
        path: '/notice/IntellectualProperty',
        component: () => import('@/views/rule/notice/IntellectualProperty')
      },
      {
        name: 'ShopLimitRule',
        path: '/rule/ShopLimitRule',
        component: () => import('@/views/rule/rules/detail/ShopLimitRule')
      },
      {
        name: 'TransactionRule',
        path: '/rule/TransactionRule',
        component: () => import('@/views/rule/rules/detail/TransactionRule')
      },
      {
        name: 'NoSaleRule',
        path: '/rule/NoSaleRule',
        component: () => import('@/views/rule/rules/detail/NoSaleRule')
      },
      {
        name: 'PunishRule',
        path: '/rule/PunishRule',
        component: () => import('@/views/rule/rules/detail/PunishRule')
      },
      {
        name: 'IndustryRule',
        path: '/rule/IndustryRule',
        component: () => import('@/views/rule/rules/detail/IndustryRule')
      },
      {
        name: 'IntellectualPropertyRule',
        path: '/rule/IntellectualPropertyRule',
        component: () => import('@/views/rule/rules/detail/IntellectualPropertyRule')
      },
      // 搜索
      {
        name: 'RuleSearch',
        path: '/ruleCenter/RuleSearch',
        component: () => import('@/views/rule/search')
      }
    ]
  },
  // StorePackage
  {
    name: 'StorePackage',
    path: '/StorePackage',
    component: () => import('@/views/shop/StorePackage/StorePackage'),
    hidden: true,
    beforeEnter: (to, from, next) => {
      // console.log(to, '表单')
      // 存在参数的时候
      if (to.query.user) {
        setusernameToken(to.query.user)
        if (to.name === 'StorePackage' && !getToken(to.query.user)) next({ path: '/register', query: { registerType: 'login' }})
        else next()
      } else {
        if (to.name === 'StorePackage' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
        else next()
      }
    }
  },
  {
    name: 'OrderPay',
    path: '/orderPay',
    component: () => import('@/views/shop/order/pay'),
    hidden: true,
    beforeEnter: (to, from, next) => {
      if (to.name === 'OrderPay' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
      else next()
    }
  },
  {
    name: 'Result',
    path: '/orderPay/result',
    component: () => import('@/views/Result'),
    hidden: true,
    beforeEnter: (to, from, next) => {
      if (to.name === 'Result' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
      else next()
    }
  },
  {
    name: 'loveVersion',
    path: '/loveVersion',
    component: () => import('@/views/shop/shopVersion/loveVersion'),
    hidden: true,
    beforeEnter: (to, from, next) => {
      if (to.name === 'loveVersion' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
      else next()
    }
  },
  {
    name: 'shopGuidance',
    path: '/shopGuidance',
    component: () => import('@/views/shop/shopGuidance/index'),
    hidden: true,
    beforeEnter: (to, from, next) => {
      if (to.name === 'loveVersion' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
      else next()
    }
  },
  {
    name: 'chart',
    path: '/chart',
    component: () => import('@/views/chart/chart'),
    hidden: true
  },

  {
    path: '/selection/',
    redirect: '/selection/home',
    component: productlayout,
    meta: { title: 'business' },
    children: [
      {
        name: 'selectionwel',
        path: '/selection/home',
        component: () => import('@/views/v5selection/home'),
        beforeEnter: (to, from, next) => {
          if (to.name === 'shop' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
          else next()
        },
        hidden: true
      },
      {
        name: 'selectionindex',
        path: '/selection/index',
        component: () => import('@/views/v5selection/index'),
        beforeEnter: (to, from, next) => {
          if (to.name === 'shop' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
          else next()
        },
        hidden: true
      },
      {
        name: 'findproduct',
        path: '/findproduct/index',
        component: () => import('@/views/v5selection/findproduct'),
        beforeEnter: (to, from, next) => {
          if (to.name === 'shop' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
          else next()
        },
        hidden: true
      },
      {
        name: 'searchproduct',
        path: '/searchproduct/index',
        component: () => import('@/views/v5selection/searchproduct.vue'),
        beforeEnter: (to, from, next) => {
          if (to.name === 'shop' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
          else next()
        },
        hidden: true
      },
      {
        name: 'detailproduct',
        path: '/detailproduct/index',
        component: () => import('@/views/v5selection/detailproduct'),
        beforeEnter: (to, from, next) => {
          if (to.name === 'shop' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
          else next()
        },
        hidden: true
      },
      {
        name: 'detailproductorder',
        path: '/detailproductorder/index',
        component: () => import('@/views/v5selection/order'),
        beforeEnter: (to, from, next) => {
          if (to.name === 'shop' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
          else next()
        },
        hidden: true
      },
      {
        name: 'favorite',
        path: '/favorite/index',
        component: () => import('@/views/v5selection/productManage/favorite'),
        beforeEnter: (to, from, next) => {
          if (to.name === 'shop' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
          else next()
        },
        hidden: true
      },
      {
        name: 'gallery',
        path: '/gallery/index',
        component: () => import('@/views/v5selection/productManage/gallery'),
        beforeEnter: (to, from, next) => {
          if (to.name === 'shop' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
          else next()
        },
        hidden: true
      },
      {
        name: 'purchased',
        path: '/purchased/index',
        component: () => import('@/views/v5selection/productManage/purchased'),
        beforeEnter: (to, from, next) => {
          if (to.name === 'shop' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
          else next()
        },
        hidden: true
      }
    ],
    hidden: true
  },
  // {
  //   name: 'two',
  //   path: '/two',
  //   component: () => import('@/views/about/contactUs'),
  //   hidden: true
  // },
  {
    name: 'Treaty',
    path: '/treaty',
    component: Layout,
    hidden: true,
    children: [
      {
        name: 'contract', // 平台合同
        path: '/treaty/contract',
        component: () => import('@/views/treaty/contract')
      },
      {
        name: 'UserAgreement', // 用户协议
        path: '/treaty/userAgreement',
        component: () => import('@/views/treaty/userAgreement')
      },
      {
        name: 'PrivacyPolicy', // 隐私政策
        path: '/treaty/privacyPolicy',
        component: () => import('@/views/treaty/PrivacyPolicy')
      }
    ]
  },
  {
    path: '/',
    redirect: '/home',
    hidden: true
  },
  {
    path: '/shop',
    redirect: '/shop/shopManagement',
    component: shopLayout,
    hidden: true,
    children: [
      // {
      //   name: 'accountInfo',
      //   path: '/shop/accountInfo',
      //   component: () => import('@/views/shop/accountInfo/accountInfo'),
      //   beforeEnter: (to, from, next) => {
      //     if (to.name === 'shop' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
      //     else next()
      //   },
      //   hidden: true
      // },
      // {
      //   name: 'credit',
      //   path: '/shop/credit',
      //   component: () => import('@/views/shop/credit/credit'),
      //   beforeEnter: (to, from, next) => {
      //     if (to.name === 'shop' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
      //     else next()
      //   },
      //   hidden: true
      // },
      // {
      //   name: 'application',
      //   path: '/shop/application',
      //   component: () => import('@/views/shop/application/application'),
      //   beforeEnter: (to, from, next) => {
      //     if (to.name === 'shop' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
      //     else next()
      //   },
      //   hidden: true
      // },
      {
        name: 'shopManagement',
        path: '/shop/shopManagement',
        component: () => import('@/views/shop/shopManagement'),
        beforeEnter: (to, from, next) => {
          // console.log(to, '表单')
          // 存在参数的时候
          // console.log(store.getters.permission)
          if (to.query.user) {
            setusernameToken(to.query.user)
            if (to.name === 'shopManagement' && !getToken(to.query.user)) next({ path: '/register', query: { registerType: 'login' }})
            else next()
          } else {
            if (to.name === 'shopManagement' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
            else next()
          }
        },
        hidden: true
      }
      // {
      //   name: 'orderManagement',
      //   path: '/shop/orderManagement',
      //   component: () => import('@/views/shop/orderManagement'),
      //   beforeEnter: (to, from, next) => {
      //     if (to.name === 'shop' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
      //     else next()
      //   },
      //   hidden: true
      // },
      // {
      //   name: 'userManagement',
      //   path: '/shop/userManagement',
      //   component: () => import('@/views/shop/userManagement'),
      //   beforeEnter: (to, from, next) => {
      //     if (to.name === 'shop' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
      //     else next()
      //   },
      //   hidden: true
      // }
    ]
  },
  // 404
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  }
]
const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
/**
 * Vue Router 前置路由回调 用于接收url参数 清除登录状态Token
 */
router.beforeEach((to, from, next) => {
  NProgress.start()
  const whiteList = ['/shop/shopManagement', '/shop/accountInfo', '/shop/application', '/shop/orderManagement', '/shop/userManagement']
  // 如果登录过-并且不是店铺管理
  if (getusernameToken() && getToken(getusernameToken())) {
    if (to.path === '/register') {
      next({ path: '/shop/shopManagement' })
    } else if (whiteList.indexOf(to.path) !== -1) {
      // 需要路由的页面
      // 获取用户信息
      store.dispatch('LOAD_ROUTER').then(roleCode => {
        router.addRoute(store.getters.permission.addRouteList)
        router.addRoute({ path: '*', redirect: '/404', hidden: true }) // 最后+404路由
        next() // 完成后跳转
      })
    } else {
      next()
    }
  } else {
    next()
  }

  // 路由前处理路由
  if (to.query.cleanToken === 'true') {
    removeToken(getusernameToken())
    next({ path: to.path })
  } else {
    next()
  }
})

/**
 * 后置路由回调
 */
router.afterEach(() => {
  NProgress.done()
})

export default router
