<template>
  <div class="hearder">
    <div class="hearder-content">
      <div class="menu" @click="collapseMenu">
        <i v-show="isCollapse" class="el-icon-s-unfold" />
        <i v-show="!isCollapse" class="el-icon-s-fold" />
      </div>
      <div class="nav-right">
        <div class="line" />
        <svg-icon class-name="hearder-bell" icon-class="hearder-bell" />
        <div class="line" />
        <el-dropdown :hide-on-click="false">
          <span class="el-dropdown-link">
            <el-avatar class="avatar">user</el-avatar>
            <span>{{ $store.getters.permission.nickName }}</span>
            <i class="el-icon-arrow-down el-icon--right" style="margin-left:10px;margin-right: 24px;" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <div @click="out()">
                退出
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { getusernameToken, removeToken, setusernameToken } from '../../utils/cookie'

export default {
  inject: ['collapseMenu'],
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    out() {
      removeToken(getusernameToken())
      setusernameToken('')
      this.$router.push({ path: '/register', query: { registerType: 'login' }})
    },

    getusernameToken() {
      return getusernameToken()
    }

  }
}
</script>

<style lang="scss" scoped>
.hearder {
  width: 100%;
  background: #fff;
  &-content {
    .menu{
      font-size: 2rem;
      margin-left: 1rem;
    }
    height: 91px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &-search{
        max-width: 400px;
        height: 46px;
        margin-right: 48px;
    }
  }
  .nav-right{
      display: flex;
      flex-direction: row;
      align-items: center;
  }
  .line{
      width: 1px;
      height: 80px;
      background-color: #F6F7FB;
  }
  .hearder-bell{
      font-size: 2rem;
      margin: 0 24px;
  }
  .avatar{
      margin-left: 24px;
      margin-right: 12px;
  }
  .el-dropdown-link{
    display: inline-flex;
    align-items: center;
  }
}
::v-deep .el-input__inner{
      border-radius: 25px;
  }
</style>
