<template>
  <el-menu
    :collapse="isCollapse"
    :default-active="getRoutePath()"
    class="menu"
    :background-color="backgroundColor"
    @close="handleClose"
    @open="handleOpen"
  >
    <div v-for="item in addRouteList.children" :key="item.id">
      <router-link v-if="!item.show" :to="item.path">
        <el-menu-item :index="item.path">
          <span>
            <img v-if="choose === item.path" class="image_margin" :src="item.chooseIcon" alt="" srcset="">
            <img v-else class="image_margin" :src="item.icon" alt="" srcset="">
          </span>
          <span slot="title" :class="choose === item.path? 'menu-text-choose':'menu-text'">{{ item.title || '暂无' }}</span>
        </el-menu-item>
      </router-link>
    </div>

    <!-- <router-link to="/shop/shopManagement">
      <el-menu-item index="/shop/shopManagement">
        <span>
          <img v-if="choose === '/shop/shopManagement'" class="image_margin" src="/images/shopmanagement/shop_select.png" alt="" srcset="">
          <img v-else class="image_margin" src="/images/shopmanagement/shop.png" alt="" srcset="">
        </span>
        <span slot="title" :class="choose === '/shop/shopManagement'? 'menu-text-choose':'menu-text'">店铺管理</span>
      </el-menu-item>
    </router-link>
    <router-link to="/shop/accountInfo">
      <el-menu-item index="/shop/accountInfo">
        <span>
          <img v-if="choose === '/shop/accountInfo'" class="image_margin" src="/images/shopmanagement/account_select.png" alt="" srcset="">
          <img v-else class="image_margin" src="/images/shopmanagement/account.png" alt="" srcset="">
        </span>
        <span slot="title" :class="choose === '/shop/accountInfo'? 'menu-text-choose':'menu-text'">账户信息</span>
      </el-menu-item>
    </router-link>
    <router-link to="/shop/orderManagement">
      <el-menu-item index="/shop/orderManagement">
        <span>
          <img v-if="choose === '/shop/orderManagement'" class="image_margin" src="/images/shopmanagement/bill_select.png" alt="" srcset="">
          <img v-else class="image_margin" src="/images/shopmanagement/bill.png" alt="" srcset="">
        </span>
        <span slot="title" :class="choose === '/shop/orderManagement'? 'menu-text-choose':'menu-text'">账单管理</span>
      </el-menu-item>
    </router-link>
    <router-link to="/shop/application">
      <el-menu-item index="/shop/application">
        <span>
          <img v-if="choose === '/shop/application'" class="image_margin" src="/images/shopmanagement/application_select.png" alt="" srcset="">
          <img v-else class="image_margin" src="/images/shopmanagement/application.png" alt="" srcset="">
        </span>
        <span slot="title" :class="choose === '/shop/application'? 'menu-text-choose':'menu-text'">应用</span>
      </el-menu-item>
    </router-link>

    <router-link to="/shop/userManagement">
      <el-menu-item index="/shop/userManagement">
        <span>
          <img v-if="choose === '/shop/userManagement'" class="image_margin" src="/images/shopmanagement/user_select.png" alt="" srcset="">
          <img v-else class="image_margin" src="/images/shopmanagement/user.png" alt="" srcset="">
        </span>
        <span slot="title" :class="choose === '/shop/userManagement'? 'menu-text-choose':'menu-text'">用户管理</span>
      </el-menu-item>
    </router-link> -->
  </el-menu>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      choose: '',
      backgroundColor: null
    }
  },
  computed: {
    ...mapGetters([
      'addRouteList'
    ])
  },
  methods: {

    handleOpen(key, keyPath) {
      this.choose = keyPath
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    getRoutePath() {
      const menuPath = this.$route.path
      this.choose = menuPath
      return menuPath
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  text-align: left;
  background-color: #002157;
}
.menu-text {
  color: #fff;
}
.menu-text-choose {
  color: #0364FF;
}

// 处理折叠卡顿
.menu:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}

// 图标边距
.image_margin{
  margin-right: 30px;
}

::v-deep .el-menu-item:focus, .el-menu-item:hover {
    outline: 0;
    color: #0364FF;
    background-color: #EEEEEE;
}

::v-deep .is-active{
  background-color: #EEEEEE;
  &::after {
    content: "";
    width: 4px;
    border-radius: 10px;
    height: 26px;
    background-color: #0364FF;
    display: block;
    position: absolute;
    top: 15px;
    right: 0;
  }
}
</style>
