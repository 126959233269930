/**
 * @author  XuHongli
 * @date  2022/2/17 13:58
 * @version 1.0
 * @description Api 接口
 */

import request from '@/utils/request'
import requestNoToken from '@/utils/noTokenRequset'

// 注册接口
export function register(data) {
  return requestNoToken({
    url: `/user/register`,
    method: 'post',
    data
  })
}

// 登录
export function login(data) {
  return requestNoToken({
    url: `/user/login`,
    method: 'post',
    data
  })
}

// 获取极光配置接口
export function jiGuangconfig() {
  return requestNoToken({
    url: `/jiguang/config`,
    method: 'get'
  })
}

// 获取
export function jiGuangRecord(params) {
  return requestNoToken({
    url: `/jiguang/messages`,
    method: 'get',
    params
  })
}

// 获取客服列表
export function getChartCustomer() {
  return requestNoToken({
    url: `/serviceuser/queryService`,
    method: 'get'
  })
}

// 新增离线表单
export function addLeaveWord(data) {
  return requestNoToken({
    url: `/leaveword`,
    method: 'post',
    data
  })
}

// 新增电话咨询
export function addPhone(phone) {
  return requestNoToken({
    url: `/phoneconsulting`,
    method: 'POST',
    data: {
      phone
    }
  })
}

// 售卖类型
export function getList() {
  return requestNoToken({
    url: '/category/list',
    method: 'get'
  })
}

// 咨询添加
export function addcounseling(data) {
  return requestNoToken({
    url: '/counseling',
    method: 'post',
    data
  })
}

// 货币
export function getCurrencyList() {
  return request({
    url: '/currency/list',
    method: 'get'
  })
}

// 地区
export function getCountryList() {
  return request({
    url: '/country/tree',
    method: 'get'
  })
}

// 编辑
export function editform(data) {
  return request({
    url: `/shop`,
    method: 'post',
    data
  })
}

export function getMd5Token() {
  return request({
    url: '/shop/md5Token',
    method: 'get'
  })
}

export function getUserInfo() {
  return request({
    url: '/user/info ',
    method: 'get'
  })
}

// 查询用户是否存在店铺
export function shopInfo() {
  return request({
    url: `/shop/getUserIdShop`,
    method: 'get'
  })
}

// 店铺信息
export function shopList() {
  return request({
    url: `/shop/list`,
    method: 'get'
  })
}

/** 店铺列表 */
export function getShopList(params) {
  return request({
    url: `/shop/page`,
    method: 'get',
    params
  })
}

export function getUserDate() {
  return request({
    url: `/user/getUserDate`,
    method: 'get'
  })
}

// 查询单条店铺信息
export function getShopListById(id) {
  return request({
    url: `/shop/${id}`,
    method: 'get'
  })
}

// 发送验证码
export function sendVerificationCode(phone, codeType = 0) {
  return requestNoToken({
    url: `/user/sendVerificationCode?phone=${phone}&codeType=${codeType}`,
    method: 'get'
  })
}

// 删除店铺发送验证码
export function sendShopDeleteVerificationCode() {
  return request({
    url: `/shop/sendShopDeleteVerificationCode`,
    method: 'get'
  })
}

// 删除店铺
export function delShopFormId(ids, code) {
  return request({
    url: `/shop/delShop?ids=${ids}&code=${code}`,
    method: 'delete'
  })
}

// 修改密码
export function updatePassWord(data) {
  return request({
    url: `/user/updatePassWord`,
    method: 'post',
    data
  })
}

// 订单表新增
export function addOrder(data) {
  return request({
    url: `/orderinfo`,
    method: 'post',
    data
  })
}

// 调用统一下单接口
export function unifiedOrder(data) {
  return request({
    url: `/orderinfo/unifiedOrder`,
    method: 'post',
    data
  })
}

// 查询订单单号params
export function queryWxOrder(data) {
  return request({
    url: `/wxPay/queryWxOrder`,
    method: 'get',
    params: data
  })
}

// 爱心版添加
export function addComboapply(data) {
  return request({
    url: `/comboapply`,
    method: 'post',
    data
  })
}

// 证件类型选择
export function getCertificatetype() {
  return request({
    url: `/certificatetype/list`,
    method: 'get'
  })
}

// 身份证选择
export function getIdcardtype() {
  return request({
    url: `/idcardtype/list`,
    method: 'get'
  })
}

// 域名检查
export function getByShopName(params) {
  return request({
    url: `/shop/getByShopName`,
    method: 'get',
    params
  })
}

// 获取套餐价格
export function getPriceList() {
  return requestNoToken({
    url: `/combo/page`,
    method: 'get'
  })
}

/**
 * 选品接口---------------------------------
 */

// Alibaba
export function getAlibabaProductList(params) {
  return request({
    url: `/supplyProduct/getAlibabaProductList`,
    method: 'get',
    params
  })
}

/**
 * 阿里巴巴详情
 */
export function getAlibabaProductDetail(productId) {
  return request({
    url: `/supplyProduct/getAlibabaProductDetailedInformation`,
    method: 'get',
    params: {
      productId
    }
  })
}

/**
 * ailibaba 分类
 */
export function getAlibabaCategory() {
  return request({
    url: `/supplyProduct/getAlibabaCategory`,
    method: 'get'
  })
}

// 品牌列表
export function getBrandProductList(params) {
  return request({
    url: `/supplyProduct/getBrandProductList`,
    method: 'get',
    params
  })
}

/**
 * 品牌详情
 */
export function getBrandProductDetailedInformation(productId) {
  return request({
    url: `/supplyProduct/getBrandProductDetailedInformation`,
    method: 'get',
    params: {
      productId
    }
  })
}

/**
 * 品牌 分类
 */
export function getBrandCategory() {
  return request({
    url: `/supplyProduct/getBrandCategory`,
    method: 'get'
  })
}

// 用户
export function getUserProductList(params) {
  return request({
    url: `/supplyProduct/getUserProductList`,
    method: 'get',
    params
  })
}

// 用户入库产品详情 /supplyProduct/getUserProductDetails
export function getUserProductDetails(id) {
  return request({
    url: `/supplyProduct/getUserProductDetails`,
    method: 'get',
    params: {
      id
    }
  })
}

/**
 * 产品入库=》上架
 */
export function addWarehouse(data) {
  return request({
    url: `/supplyProduct/addWarehouse`,
    method: 'post',
    data
  })
}

/**
 * 收藏 /usercollect/page
 */
export function getUsercollect(params) {
  return request({
    url: `/usercollect/page`,
    method: 'get',
    params
  })
}

/**
 * 新增收藏 /usercollect/addCollect
 */
export function addCollect(data) {
  return request({
    url: `/usercollect/addCollect`,
    method: 'post',
    data
  })
}

/**
 * 取消收藏 /usercollect/deleteCollect
 */
export function deleteCollect(id) {
  return request({
    url: `/usercollect/deleteCollect`,
    method: 'post',
    data: {
      id
    }
  })
}

/**
 * 已订单列表 /productorderinfo/page
 */
export function getOrderList(params) {
  return request({
    url: `/productorderinfo/page`,
    method: 'get',
    params
  })
}

/**
 * 新增订单
 */
export function addProductorder(data) {
  return request({
    url: `/productorderinfo/addOrder`,
    method: 'post',
    data
  })
}

/**
 * 统一下单接口 payOrderNo, payType:1、微信支付；2、支付宝支付
 */
export function productunifiedOrder(data) {
  return request({
    url: `/productorderinfo/unifiedOrder`,
    method: 'post',
    data
  })
}

/**
 * 轮询订单是否支付成功
 */
export function roundRobinOrder(data) {
  return request({
    url: `/productorderinfo/queryPayOrder`,
    method: 'get',
    params: data
  })
}

/**
 * 用户地址
 */
export function getUseraddress() {
  return request({
    url: `/useraddress/page`,
    method: 'get'
  })
}

/**
 * 地址新增 /useraddress/addUserAddress
 */
export function addUserAddress(data) {
  return request({
    url: `/useraddress/addUserAddress`,
    method: 'post',
    data
  })
}

/**
 * 地址修改 /useraddress/addUserAddress
 */
export function editUserAddress(data) {
  return request({
    url: `/useraddress`,
    method: 'put',
    data
  })
}

/**
 * 地址删除
 */
export function delUseraddress(id) {
  return request({
    url: '/useraddress/' + id,
    method: 'delete'
  })
}

/**
 * 中国地区管理 /chinaregion/tree
 */
export function getchinaregion() {
  return request({
    url: `/chinaregion/tree`,
    method: 'get'
  })
}

/**
 * 入驻选品新增
 */
export function addSupplyUserEnter() {
  return request({
    url: `/supplyuserenter/addSupplyUserEnter`,
    method: 'post'
  })
}
