import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementLocale from 'element-ui/lib/locale'

// BootStrap 接入
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// Ant
import {
  avatar as antAvatar,
  badge as antbBdge,
  Breadcrumb as antBreadcrumb,
  Button as antButton,
  Card as antCard,
  Carousel,
  Cascader as antcascader,
  Checkbox as antCheckBox,
  Col,
  Col as antCol,
  DatePicker as antDatePicker,
  FormModel as antFormModel,
  Icon as antIcon,
  Input as antInput,
  InputNumber as antInputNumber,
  List,
  Menu as antMenu,
  Modal as antModal,
  pagination as antpagination,
  Radio as antRadio,
  Result as antResult,
  Row as antRow,
  Select as antSelect,
  Spin as antSpin,
  Table as antTable,
  Tabs as antTabs,
  Tag as antTag,
  Upload as antUpload,
  Popconfirm as antPopconfirm,
  AutoComplete as antAutoComplete
} from 'ant-design-vue'
// Swiper
import '@/styles/swiper-bundle.min.css'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
// i18n
import i18n from '@/lang'

// wow
import wow from 'wowjs'
import 'wowjs/css/libs/animate.css'
// svg
import '@/icons'
// 按需引入Element ui
import {
  Aside,
  Avatar,
  Button,
  Card,
  Cascader,
  Checkbox,
  Container,
  Dialog,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Form,
  FormItem,
  Header,
  Input,
  Loading,
  Main,
  Menu,
  MenuItem,
  MenuItemGroup,
  Message,
  MessageBox,
  Option,
  Pagination,
  Select,
  Skeleton,
  SkeletonItem,
  Submenu,
  Table,
  TableColumn,
  TabPane,
  Tabs,
  Upload
} from 'element-ui'
import moment from 'moment'
// 矢量图标库
import '@/assets/icon/iconfont.css'
import '@/styles/index.scss' // global css
import VueClipboards from 'vue-clipboard2'

Vue.component(antTable.name, antTable)
Vue.component(antRadio.name, antRadio)
Vue.component(antRadio.Group.name, antRadio.Group)
Vue.component(antModal.name, antModal)
Vue.component(antButton.name, antButton)
Vue.component(antResult.name, antResult)
Vue.component(antSpin.name, antSpin)
Vue.component(antInput.name, antInput)
Vue.component(antInput.Search.name, antInput.Search)
Vue.component(antInputNumber.name, antInputNumber)
Vue.component(antTag.name, antTag)
Vue.component(antCheckBox.name, antCheckBox)
Vue.component(antCheckBox.Group.name, antCheckBox.Group)
Vue.component(antRow.name, antRow)
Vue.component(antCol.name, antCol)
Vue.component(antCard.name, antCard)
Vue.component(antBreadcrumb.name, antBreadcrumb)
Vue.component(antBreadcrumb.Item.name, antBreadcrumb.Item)
Vue.use(antAvatar)
Vue.use(antbBdge)
Vue.use(antFormModel)
Vue.use(antSelect)
Vue.use(antDatePicker)
Vue.use(antpagination)
Vue.use(antUpload)
Vue.use(antIcon)
Vue.use(antPopconfirm)
Vue.use(antAutoComplete)

Vue.component(antcascader.name, antcascader)
Vue.component(antTable.name, antTable)
Vue.component(antRadio.name, antRadio)
Vue.component(antRadio.Group.name, antRadio.Group)
Vue.component(antModal.name, antModal)
Vue.component(antButton.name, antButton)
Vue.component(antResult.name, antResult)
Vue.component(antSpin.name, antSpin)
Vue.component(antInput.name, antInput)
Vue.component(antInput.Search.name, antInput.Search)
Vue.component(antInputNumber.name, antInputNumber)
Vue.component(antTag.name, antTag)
Vue.component(antCheckBox.name, antCheckBox)
Vue.component(antCheckBox.Group.name, antCheckBox.Group)
Vue.component(antRow.name, antRow)
Vue.component(antCol.name, antCol)
Vue.component(antCard.name, antCard)
Vue.component(antBreadcrumb.name, antBreadcrumb)
Vue.component(antBreadcrumb.Item.name, antBreadcrumb.Item)
Vue.use(antAvatar)
Vue.use(antbBdge)
Vue.use(antFormModel)
Vue.use(antSelect)
Vue.use(antDatePicker)
Vue.use(antpagination)
Vue.component(VueSlickCarousel.name, VueSlickCarousel)

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.use(List)
Vue.use(antMenu)
Vue.use(antTabs)
Vue.use(antInput)
Vue.use(Carousel)
Vue.use(Col)
Vue.component(antTable.name, antTable)
Vue.component(antRadio.name, antRadio)
Vue.component(antRadio.Group.name, antRadio.Group)
Vue.component(antModal.name, antModal)
Vue.component(antButton.name, antButton)
Vue.component(antResult.name, antResult)
Vue.component(antSpin.name, antSpin)

Vue.prototype.$login = function() {
  const routeData = this.$router.resolve({ path: '/register', query: { registerType: 'login' }})
  window.open(routeData.href, '_blank')
}

Vue.prototype.$register = function() {
  const routeData = this.$router.resolve({ path: '/register', query: { registerType: 'register' }})
  window.open(routeData.href, '_blank')
}

Vue.prototype.$wow = wow
Vue.component(Input.name, Input)
Vue.component(Button.name, Button)
Vue.component(Checkbox.name, Checkbox)
Vue.component(Table.name, Table)
Vue.component(TableColumn.name, TableColumn)
Vue.component(Dropdown.name, Dropdown)
Vue.component(DropdownMenu.name, DropdownMenu)
Vue.component(DropdownItem.name, DropdownItem)
Vue.component(Form.name, Form)
Vue.component(FormItem.name, FormItem)
Vue.component(Select.name, Select)
Vue.component(Option.name, Option)
Vue.component(Avatar.name, Avatar)
Vue.component(Cascader.name, Cascader)
Vue.component(Upload.name, Upload)
Vue.component(Dialog.name, Dialog)
Vue.component(Tabs.name, Tabs)
Vue.component(TabPane.name, TabPane)
Vue.component(Pagination.name, Pagination)
Vue.component(Container.name, Container)
Vue.component(Aside.name, Aside)
Vue.component(Header.name, Header)
Vue.component(Main.name, Main)
Vue.component(Menu.name, Menu)
Vue.component(MenuItemGroup.name, MenuItemGroup)
Vue.component(MenuItem.name, MenuItem)
Vue.component(Submenu.name, Submenu)
Vue.component(Card.name, Card)
Vue.component(Skeleton.name, Skeleton)
Vue.component(SkeletonItem.name, SkeletonItem)
Vue.use(Loading.directive)
Vue.prototype.$Loading = Loading
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$message = Message
ElementLocale.i18n((key, value) => i18n.t(key, value))
Vue.config.productionTip = false

Vue.use(VueClipboards)

moment.locale('zh-cn')
Vue.prototype.$moment = moment

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
