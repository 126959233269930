/**
 * @author  XuHongli
 * @date  2022/2/17 13:58
 * @version 1.0
 * @description english 语言模板
 */

export default {
  route: {
    home: 'Start',
    b2c: 'B2C',
    business: 'Business',
    support: 'Support',
    openSource: 'Open Source',
    template: 'Template',
    ServicePurchase: 'Service Purchase',
    feature: 'Feature',
    module: 'Standardized module',
    customise: 'Customise',
    mrd: 'MRD',
    google: 'Google Ads',
    facebook: 'facebook Ads',
    seo: 'SEO support',
    overseasB2B: 'Overseas B2B platform',
    social: 'Social media to package',
    seller: 'Platform Seller',
    logistics: 'Intelligent logistics',
    case: 'case'
  },
  navBar: {
    user: 'User',
    login: 'Login',
    register: 'Register',
    tryIt: 'Try it'
  },
  footer: {
    aboutUs: 'About Us Company',
    aboutUsContent: 'One-stop service, save worry, save effort and safety. Payment, logistics, flow, we have everything you want',
    address: 'Address： 23/F TongXin Building,Binhe avenue ShenZhen City, China',
    telephone: '400-829-9969 (only working day)', // (24/7 Support Line)
    //  Our Services
    ourServices: 'Our Services',
    home: 'Home',
    business: 'Business',
    functionalAdvantage: 'Functional Advantage',
    pricingPlan: 'Pricing Plan',
    serviceSupport: 'Service Support',
    template: 'Template',
    customise: 'Customise',
    // Business
    // Newsletter
    newsletter: 'Newsletter',
    newsletterContent: 'Subscribe our newsletter to get our latest update all blog & news',
    placeholder: 'Your Email Address',
    // Copyright ©
    about: 'User Agreement',
    privacyPolicy: 'Privacy Policy',
    FAQs: 'FAQs',
    support: 'Support'
  },
  PricingSection: {
    headerTitle: 'Pricing plans for you.'
  },
  home: {
    title: 'High quality solutions quickly help you get out to sea',
    articleList: [
      {
        p1: 'Mass industry templates are free to use',
        p2: 'One key to use, one key to switch',
        text: ['Provide 1000+ responsive templates for free use', 'Support homepage visual DIY', 'One-click switching of style templates, no need to re-upload data']
      },
      {
        p1: 'Vllshop tens of millions of SKU supply chain',
        text: ['Gather global resources, connect hundreds of countries, unimpeded, efficient delivery, and fast transportation.', 'Tens of millions of hot-selling products data, covering apparel, 3C, home furnishings and other categories, to help you quickly understand the market and easily select products', 'Free logistics choice, good source of goods find Vllshop to help you get one']
      },
      {
        p1: 'Vllshop independent station marketing method',
        p2: 'Help you connect to the whole network solution',
        text: ['Google，Facebook，Bing，Yandex，Youtube，', 'Various promotion methods such as LinkedIn, TikTok, Pinterest, etc.', 'One-time login, permanent management, simultaneous management of multiple accounts, sub-account permission management']
      },
      {
        p1: 'Quickly build an independent station in three minutes',
        text: ['The platform follows the philosophy that "fast" is king and "simple" is the highest state', 'Self-made fool-like operations help you quickly become a little expert in website construction,', 'Build your own cross-border e-commerce in 3 minutes by building blocks, simple registration']
      }
    ],
    banner_try: {
      title1: 'Full support',
      title2: 'Independent and self-built station operation',
      button: 'Try It'
    },
    partners: 'Partners'
  }
}
