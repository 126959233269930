import { getUserInfo } from '../../api/register'
import shopRouter from '../../router/shopRouter'

/**
 * @author  XuHongli
 * @date  2022/3/8 18:09
 * @version 1.0
 * @description 权限Vuex
 */
export default {
  state: {
    roleCode: '', // 权限名称
    addRouteList: {}, // 需要添加的路由对象,
    nickName: ''
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouteList = routers
    },
    SET_NICKNAME: (state, nickName) => {
      state.nickName = nickName
    },
    SET_ROLECODE: (state, roleCode) => {
      state.roleCode = roleCode
    }
  },
  actions: {
    LOAD_ROUTER(state) {
      const result = new Promise((resolve, reject) => {
        getUserInfo().then(res => {
          if (res.code === 0) {
            /** 请求成功 */
            state.commit('SET_ROLECODE', res.data.roleCode)
            state.commit('SET_NICKNAME', res.data.nickName || '暂无')

            if (res.data.roleCode === 'MASTER_ACCOUNT') {
              /** 主账号 MASTER_ACCOUNT */
              state.commit('SET_ROUTERS', shopRouter || [])
            } else {
              /** 子账号 SUB_ACCOUNT*/
              const { ...sublist } = shopRouter
              // const sublist = shopRouter
              const list = sublist.children.filter((item) => {
                return !item.admin
              })
              sublist.children = list
              state.commit('SET_ROUTERS', sublist || [])
            }
            resolve(res.data.roleCode)
          } else {
            /** 请求报错 */
            reject(res.msg)
          }
        }).catch(err => {
          reject(err)
        })
      })

      return result
    }
  },
  modules: {
  }
}
