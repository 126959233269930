/**
 * @author  XuHongli
 * @date  2022/3/8 14:12
 * @version 1.0
 * @description
 */

import Vue from 'vue'
import Vuex from 'vuex'
import permission from './modules/permission'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters: {
    permission: state => state.permission,
    addRouteList: state => state.permission.addRouteList,
    roleCode: state => state.permission.roleCode,
    nickName: state => state.permission.nickName
  },
  modules: {
    permission
  }
})
