/**
 * @author  XuHongli
 * @date  2022/2/17 13:58
 * @version 1.0
 * @description cookie 操作类
 */

import Cookies from 'js-cookie'

const languageKey = 'language'
export const getLanguage = () => Cookies.get(languageKey)
export const setLanguage = (language) => Cookies.set(languageKey, language, { expires: 7 })

const usernameKey = 'username'
export const getusernameToken = () => Cookies.get(usernameKey)
export const setusernameToken = (userData) => Cookies.set(usernameKey, userData, { expires: 7 })

// const tokenKey = 'token'
// 1.以用户手机缓存token
export const getToken = (tokenKey) => Cookies.get(tokenKey)
export const setToken = (tokenKey, token) => Cookies.set(tokenKey, token, { expires: 7 })
export const removeToken = (tokenKey) => Cookies.remove(tokenKey)

const packageKey = 'packageKey'
export const getShopPackage = () => Cookies.get(packageKey)
export const setShopPackage = (packageObj) => Cookies.set(packageKey, packageObj, { expires: 7 })

const chartKey = 'chartKey'
export const getChartKey = () => Cookies.get(chartKey)
export const setChartKey = (chartobj) => Cookies.set(chartKey, chartobj, { expires: 7 })
