/**
 * @author  XuHongli
 * @date  2022/2/17 13:58
 * @version 1.0
 * @description 多语言中文 文字模板
 */
export default {
  route: {
    home: '首页 ',
    b2c: 'B2C合作',
    price: '定价方案',
    business: '功能介绍',
    about: '关于',
    CouldPhone: 'Tiktok云手机',
    template: '商店模板',
    ServicePurchase: '服务选购',
    feature: '服务亮点',
    module: '标准化模块',
    customise: '研发定制',
    mrd: '平台MRD',
    google: 'Google广告',
    facebook: 'facebook广告',
    seo: 'SEO支持',
    oversea: '海外推广',
    social: '社交主题包装',
    seller: '平台卖家',
    logistics: '智能物流',
    case: '案例',
    contactUs: '联系我们',
    aboutUs: '关于我们'

  },
  navBar: {
    user: '用户',
    login: '登录',
    register: '注册',
    tryIt: '开始免费试用'
  },
  footer: {
    aboutUs: '关于我们',
    aboutUsContent: '深圳市万鲸科技有限公司',
    address: '地址： 广东省深圳市南山区北京航空航天大厦1座7003',
    telephone: '400-829-9969',
    //  Our Services
    ourServices: '服务',
    home: '首页',
    functionalAdvantage: '功能介绍',
    pricingPlan: '定价方案',
    oversea: '海外推广',
    contactUs: '联系我们',
    // Business
    case: '案例',
    business: '电商平台',
    template: '商店模板',
    module: '标准化模块',
    customise: '研发定制',
    seller: '平台卖家',
    logistics: '智能物流',
    // Newsletter
    newsletter: '获取最新资讯',
    newsletterContent: '请留下邮箱以便获取我们所有最新资讯与动态',
    newsletterPlaceholder: '请输入电子邮箱',
    // Copyright ©
    about: '用户协议',
    privacyPolicy: '隐私政策',
    FAQs: '常见问题',
    support: '支持'
  },
  PricingSection: {
    headerTitle: '为您制定定价计划'
  },
  home: {
    title: '高品质解决方案，快速助您出海',
    articleList: [
      {
        p1: '海量行业模板免费使用',
        p2: '一键使用、一键切换',
        text: ['提供多行业精美模板免费使用', '支持首页可视DIY', '风格模板一键切换，无需重新上传资料']
      },
      {
        p1: '千万级SKU供应链',
        text: ['聚全球资源，联通数百国家，畅行无阻，高效出库，快速运输。', '千万量级的热卖商品数据，覆盖服饰、3C、家居等大类，助您快速了解市场，轻松选品', '']
      },
      {
        p1: '独立站营销方式',
        p2: '帮您对接全网解决方案',
        text: ['Google，Facebook，Bing，Yandex，Youtube', 'LinkedIn，TikTok，Pinterest等多种推广方式，', '帮您对接各大平台，亿级美金的投放经验助您低成本高转化']
      },
      {
        p1: '三分钟快速搭建独立站',
        text: ['平台遵循“快”才是王道、“简单”才是最高境界的理念', '自制傻瓜式操作助您快速成为建站小能手、', '积木式搭建“3”分钟，简单操作，轻松拥有自己个性化店铺']
      }
    ],
    banner_try: {
      title1: '全方位',
      title2: '运营支持',
      button: '尝试一下'
    },
    partners: '合作伙伴'
  }
}
