<!-- 规则中心 Navbar -->
<template>
  <div class="NavBar">
    <b-navbar toggleable="lg" type="light" >
      <b-navbar-brand href="#" @click="goHome()">
        <b-img :src="require('../../assets/img/Logo-drak.png')" style="height: 40px;margin: 15px"/>
      </b-navbar-brand>
      <b-nav-item href="/ruleCenter" style="margin-left: -50px">
        <a href="/ruleCenter">规则中心</a>
      </b-nav-item>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      navList: [],
      navListMobile: [],
      hoverNavItem: 0,
      FlagIn: true
    }
  },
  created() {
  },
  methods: {
    goHome() {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.NavBar > nav{
  background-color: #fafafa !important;
}
// user&btn
.user{
  line-height: 38px;
  cursor: pointer;
}
.userbtn{
  margin: 0px 2rem 0 4rem;
  background-color: blue;
  border-radius: 50px;
  height: 38px;
  width: 150px;
}

.nav-item{
  margin: 0 2rem;
  >a{
    color: #141414 !important;
  }
}
::v-deep .navbar-light .navbar-nav .nav-link{
  color: #141414 !important;
}
.show-pc{
  display: unset;
}
.show-mobile{
  display: none;
}

.NavBar{
  font-family: "nunito,SourceHanSans";
  font-size: 1.8rem;
}
@media (max-width: 991px) {
  .form-inline{
    >.btn{
      display: none;
    }
  }
  // 隐藏pc导航，显示mobile导航
  .show-pc{
    display: none;
  }
  .show-mobile{
    display: unset;
  }
  ::v-deep .dropdown-item{
    opacity: 0.6;
    text-align: center;
  }
}

@media only screen and (min-width:992px) and (max-width:1199px){
  .nav-item{
  margin: 0 0.8rem;
  >a{
    color: #141414 !important;
  }
}

}
</style>
