<!-- 官网静态页面通用布局 -->
<template>
  <div class="layout">
    <header >
      <Navbar />
    </header>
    <main>
      <router-view/>
    </main>
    <div v-show="showMsgBox" class="mbox animated slideInRight">
      <div class="title-box">
        <!-- <div class="title">
          <img src="../assets/img/chart/charticon.png" alt="">
        </div> -->
        <img class="title" src="../assets/img/chart/charticon.png" alt="">
        <div class="msg-close" @click="showMsgBox=false">
          关闭
        </div>
      </div>
      <template v-if="showOffForm">
        <div ref="mbody" class="msg-body">
          <div v-for="(item,index) in recordList" :key="index" class="msg" :class="item.self?'reversal':'noreversal'">
            <div class="msg-avatar" :style="item.self?'margin-left:10px':'margin-right:10px'">
              <el-avatar v-show="!item.self" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" />
            </div>
            <div>
              <div v-show="!item.self" style="text-align: left;">
                {{ item.userName }}
              </div>
              <div class="msg-content" :style="item.self?'background-color: #576C8A;color:#fff':'background-color: #E2E9FC'">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div v-show="showPhoneInput" class="freephone" style="display:flex;padding: 5px;background-color: #152243;">
            <el-input v-model="yourphone" placeholder="输入电话号码" size="small" />
            <el-button type="primary" class="phonebtn" size="small" @click="sendPhone()">
              免费通话
            </el-button>
          </div>
          <div v-show="!showPhoneInput" style="line-height:42px">
            为了保护您的隐私，采用虚拟号，请放心接听
          </div>
        </div>
        <div class="threebox">
          <div class="lbox" @click="toPage(1)">
            免费使用
          </div>
          <div class="lbox" @click="toPage(2)">
            产品亮点
          </div>
          <div class="lbox" @click="toPage(3)">
            套餐价格
          </div>
        </div>
        <div class="msg-foot">
          <el-input v-model="msgContent" class="inputcla" show-word-limit maxlength="200" :rows="3" style="margin-right: 5px;margin-bottom: 10px;" type="textarea" placeholder="请输入信息" @keyup.enter.native="sendSingleMsg()" />
          <div style="align-self: flex-end;">
            <!-- <span style="vertical-align: bottom;margin-right: 5px;">200/200</span> -->
            <el-button class="sendbtn" type="primary" @click="sendSingleMsg()">
              发送
            </el-button>
          </div>
        </div>
      </template>
      <chatFrom v-else />
    </div>
    <div v-show="!showMsgBox" class="minibox animated fadeIn">
      <!-- <div>

      </div> -->

      <div>
        <!-- <div @click="cc()"> 在线 </div> -->
        <div class="showminibox" @click="cc()">
          <img style="width:25px;height:25px" src="../assets/img/chart/onlinec.png" alt="">
          <p style="margin-top:8px">
            在线咨询
          </p>
        </div>
        <div style="height:1px;background-color: #fff;margin: 0px 3px;opacity: 0.4;" />
        <div class="showminibox" @click="showCall()">
          <img style="width:25px;height:25px" src="../assets/img/chart/phone.png" alt="">
          <p style="margin-top:8px">
            电话咨询
          </p>
        </div>
        <div style="height:1px;background-color: #fff;margin: 0px 3px;opacity: 0.4;" />
        <div class="showminibox" @mouseenter="showWjCode(true)" @mouseleave="showWjCode(false)">
          <img style="width:25px;height:25px" src="../assets/img/chart/code.png" alt="">
          <p style="margin-top:8px">
            公众号
          </p>
        </div>

        <!-- 给我回电 -->
        <div v-show="phoneVisibility" class="wjphone">
          <span class="close" @click="showCall()">x</span>
          <div v-show="showPhoneInput">
            <div style="display:flex;padding: 3px;background-color: #152243;">
              <el-input v-model="yourphone" style="width:150px" placeholder="输入电话号码" size="small" />
              <el-button type="primary" class="phonebtn" size="small" style="font-size:13px;width:80px" @click="sendPhone()">
                给我回电!
              </el-button>
            </div>
            <p style="text-align: left;padding-left: 3px;margin-top: 5px;" :style="PhoneTip?'color:red':''">
              {{ PhoneTip? '请输入正确的号码' : '免费电话咨询服务，请放心接听' }}
              <!-- 免费电话咨询服务，请放心接听 -->
            </p>
          </div>
          <div v-show="!showPhoneInput" style="display:flex;justify-content: space-between;flex: 1;">
            <p style="text-align: left;padding-left: 3px;margin-top: 5px;font-size: 15px;margin-left: 15px;">
              发送成功!请留意电话接听
            </p>
            <p style="text-align: left;padding-left: 3px;font-size: 13px;margin-top: 5px;cursor: pointer;color: rgb(47, 102, 253);line-height: 23px;" @click="showPhone(true)">
              &lt; 返回
            </p>
          </div>
        </div>

        <!-- 二维码 -->
        <div v-show="wjCodeVisibility" class="wjcode">
          <img src="../assets/img/chart/wjcode.png" alt="">
        </div>
      </div>
    </div>
    <footer>
      <Footer />
    </footer>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/2/17 13:58
 * @version 1.0
 * @description 通用官网静态页面布局文件
 */

import '@/docs/jmessage-sdk-web.2.6.0.min'
import { Snowflake } from '@/docs/Snowflake'
import Navbar from '@/layout/Navbar'
import Footer from '@/layout/footer'
import { addPhone, getChartCustomer, jiGuangconfig, jiGuangRecord } from '@/api/register'
import { isvalidPhone } from '@/utils/validate'
import { getChartKey, setChartKey } from '@/utils/cookie'
import chatFrom from '@/components/chat/form'

export default {
  components: { Navbar, Footer, chatFrom },
  data() {
    return {
      PhoneTip: false,
      JIM: '',
      msgContent: '',
      recordList: [],
      showMsgBox: false,
      customer: {}, // 客服
      chooseName: '', // 选择的客服
      selfInfo: { // 自己的极光
        userName: '',
        password: ''
      },
      yourphone: '',
      wjCodeVisibility: false,
      phoneVisibility: false,
      showPhoneInput: true,
      appkey: '',
      showOffForm: true // 是否显示离线表单
    }
  },
  created() {
    // eslint-disable-next-line no-undef
    this.JIM = new JMessage({
      debug: false
    })
    jiGuangconfig().then((res) => {
      if (res.code !== 401) {
        this.appkey = res.data.appkey
        this.Jginit(res.data)
      }
    }).catch((err) => {
      console.log(err)
    })
    this.getChartCustomerList()
  },
  destroyed() {
    this.JIM.loginOut()
  },
  methods: {
    Jginit(data) {
      const that = this

      that.JIM.init({
        'appkey': data.appkey,
        'random_str': data.random_str,
        'signature': data.signature,
        'timestamp': data.timestamp,
        'flag': data.flag
      }).onSuccess(function(data) {
        // console.log('初始化：success:' + JSON.stringify(data))
        // that.loginJIM() -初始化后不登录极光
      }).onFail(function(data) {
        // console.log('初始化: error:' + JSON.stringify(data))
      })
    },
    // 极光登录
    loginJIM(userInfo) {
      const that = this
      const username = userInfo.username
      const password = userInfo.password
      return new Promise((resolve, reject) => {
        const JIM = that.JIM
        if (JIM.isLogin()) {
          console.log('极光IM已经登录，无需再登录')
          that.showMsgBox = true
          resolve('success')
          return
        }
        // 登录
        JIM.login({
          'username': username,
          'password': password
        }).onSuccess(function(data) {
          // console.log('极光IM登录成功')
          that.getSelfInfo()
        }).onFail(function(data) {
          // 如果用户不存在，则注册账号
          if (data.code === 880103) {
            const nickname = userInfo.username
            JIM.register({
              'username': username,
              'password': password,
              'nickname': nickname,
              'extras': {
                'avatar': '../assets/img/avatar/1.png'
              }
            }).onSuccess(function(data) {
              // console.log('极光IM注册成功')
              // 登录
              JIM.login({
                'username': username,
                'password': password
              }).onSuccess(function(data) {
                // console.log('极光IM登录成功')
                that.getSelfInfo()
              }).onFail(function(data) {
                // console.log(data)
                resolve('success')
              })
            }).onFail(function(data) {
              // console.log('JIM -注册失败' + data)
              resolve('success')
            })
          } else if (data.code === 880109 || data.code === 880107) {
            // console.log(data)
          } else {
            // console.log('极光IM登录失败' + data)
            // console.log('username,password：' + username + ',' + password)
            resolve('success')
          }
        })
      })
    },
    loginJIMAfter() {
      const that = this
      that.showMsgBox = true
      that.JIM.onMsgReceive(function(data) {
        that.recordList.push({ text: data.messages[0].content.msg_body.text, self: false, userName: that.chooseName })
        that.$nextTick(function() {
          that.$refs.mbody.scrollTop = that.$refs.mbody.scrollHeight - that.$refs.mbody.clientHeight
        })
        data = JSON.stringify(data)
        // console.log('接受信息:' + data)
      })
      that.JIM.onDisconnect(function() {
        // console.log('【disconnect】')
      }) // 异常断线监听
      // 要聊的人
      that.getJiGuangRecord({ name: that.chooseName })
    },
    getSelfInfo() {
      const that = this
      that.JIM.getUserInfo({
        'username': that.chooseName
      }).onSuccess(function(data) {
        // console.log('客服的信息:' + JSON.stringify(data))
        // 缓存客服
        that.loginJIMAfter() // 用户成功后
      }).onFail(function(data) {
        // const aChild = document.createElement('div')
        // aChild.innerText = '客服人员暂时没有上线哦、如有需要请您留言'
        // that.$refs.mbody.appendChild(aChild)
        that.showOffForm = false
        // that.$message.warning('客服人员暂时没有上线哦、如有需要请您留言')
        // console.log('error:' + JSON.stringify(data))
      })
    },
    // 发送信息
    sendSingleMsg() {
      const that = this
      // TODO 发送信息
      this.JIM.sendSingleMsg({
        'target_username': that.chooseName,
        'appkey': that.appkey,
        'content': that.msgContent,
        'no_offline': false,
        'no_notification': false,
        // 'custom_notification':{'enabled':true,'title':'title','alert':'alert','at_prefix':'atprefix'}
        'need_receipt': true
      }).onSuccess(function(data, msg) {
        // console.log('success data:' + JSON.stringify(data) + '发送成功')
        // 将信息放在聊天list
        that.recordList.push({ text: that.msgContent, self: true })
        that.$nextTick(function() {
          that.$refs.mbody.scrollTop = that.$refs.mbody.scrollHeight - that.$refs.mbody.clientHeight
        })
        that.msgContent = ''
      }).onFail(function(data) {
        // console.log('error:' + JSON.stringify(data) + '发送失败')
      })
    },
    // 获取用户聊天记录
    getJiGuangRecord(c_item) {
      const that = this
      // console.log('9', c_item)
      const obj = {
        endTime: that.$moment().format('YYYY-MM-DD HH:mm:ss'),
        beginTime: that.$moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss'),
        userName: c_item.name
      }
      jiGuangRecord(obj).then((res) => {
        const obj = JSON.parse(res.data) // res.data.messages
        const list = obj.messages
        // console.log(obj.messages[obj.messages.length - 1])
        that.recordList = []
        for (let index = 0; index < list.length; index++) {
          const element = list[index]
          if (c_item.name === element.from_id && element.target_id === that.selfInfo.username) {
            // 别人发的，在右边
            that.recordList.push({ text: element.msg_body.text, self: false, userName: c_item.name })
          } else if (element.from_id === that.selfInfo.username && element.target_id === c_item.name) {
            // 如果是自己发送的，在右边
            that.recordList.push({ text: element.msg_body.text, self: true })
          }
        }
        // console.log(that.recordList, 'kkkk')
        that.$nextTick(function() {
          that.$refs.mbody.scrollTop =
                that.$refs.mbody.scrollHeight - that.$refs.mbody.clientHeight
        })
      })
    },
    // 获取聊天客服列表
    getChartCustomerList() {
      getChartCustomer().then(res => {
        // console.log(res, '客服')
        this.customer = res.data
      })
    },
    // 选择客服 - 再登录。并赋值客服
    chooseCustomer(item) {
      // 获取极光配置
      jiGuangconfig().then((res) => {
        if (res.code !== 401) {
          this.appkey = res.data.appkey
          this.Jginit(res.data)
        }
      })
      // 获取用户
      const abc = getChartKey()
      // 如果之前没有登录过
      if (!abc) {
        // 生成一个随机ID，并保存
        const snowflake = new Snowflake(1n, 1n, 0n)
        const tempIds = snowflake.nextId()
        // 保存
        setChartKey(tempIds)
      }
      this.selfInfo.username = getChartKey()
      this.selfInfo.password = getChartKey()
      this.loginJIM({
        username: this.selfInfo.username,
        password: this.selfInfo.password
      })
      // 对方（客服）必须存在
      this.chooseName = this.customer.name // 'xuqijin110' // item.name
      // this.showMsgBox = true
    },
    // 留下您的电话
    sendPhone() {
      if (!isvalidPhone(this.yourphone)) {
        // this.$message.error('格式不对')
        this.PhoneTip = true
        return
      }
      addPhone(this.yourphone).then(res => {
        if (res.code === 0) {
          // this.$message.success('提交成功')
          this.PhoneTip = false
          this.showPhoneInput = false
        }
      })
    },
    showWjCode(visibility) {
      this.wjCodeVisibility = visibility
    },
    // 页面跳转
    toPage(num) {
      if (num === 1) {
        this.$router.push({ path: '/register', query: { registerType: 'login' }})
      }
      if (num === 2) {
        this.$router.push({ path: '/business/customise' })
      }
      if (num === 3) {
        this.$router.push({ path: '/price' })
      }
    },
    showCall() {
      this.phoneVisibility = !this.phoneVisibility
    },
    // 在线联系
    cc() {
      window.open('/chart', '_blank', 'height=563,width=800,top=50,left=200,status=yes,toolbar=no,menubar=no,resizable=no,scrollbars=no,location=no,titlebar=no')
    },

    // 显示电话号码
    showPhone(show) {
      this.showPhoneInput = show
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
}

.mbox{
  position: fixed;
  right: 0px;
  bottom: 0px;
  background-color: rgb(255, 255, 255);
  border: gray solid 1px;
  width: 350px;
  height: 500px;
  z-index: 99;
}
.minibox{
  position: fixed;
  right: 0px;
  bottom: calc(50vh - 125px);
  margin: 15px 0;
  // border-radius: 50px;
  z-index: 100;
  background-color: #152243;
}
.customeritem{
  cursor: pointer;
}

.title-box{
    display: flex;
    align-items: center;
    justify-content: start;
    background-color: #152243;
}
.title{
  // flex: 1;
  font-size: 18px;
  width: 120px;
  height: 24px;
  margin: 8px;
  // border-bottom: 1px solid rgb(214, 214, 214);
}
.msg-close{
  position: absolute;
  right: 10px;
  color: #fff;
  cursor: pointer;
}
.msg-body{
  background: #F8F8F8;
  padding: 15px 15px 5px 15px;
  height: 256px;
  overflow-x: hidden;
  overflow-y: scroll;
}
// 隐藏滑动条
.msg-body::-webkit-scrollbar { width: 0 !important }
.msg-body { -ms-overflow-style: none; } //ie10+
.msg-body { overflow: -moz-scrollbars-none; }
.msg{
  display: flex;
  // align-items: center;
}
.msg + .msg{
  margin-top: 5px;
}
.msg-content{
  max-width: 170px;
  word-break: break-all;
  padding: 4px 8px 4px 8px;
  border-radius: 12px;
  font-size: 18px;
  text-align: left;
  background-color: #fff;
}
.noreversal{
  flex-direction: row;
}
.reversal{
  flex-direction: row-reverse;
}
.msg-foot{
  // border-top: 1px solid gainsboro;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 5px 8px;
}
// 输入框
.inputcla{
  border: unset !important;
  // margin-bottom: 10px;
}
::v-deep .el-input__inner{
  border: unset;
  border-radius: unset;
}
.sendbtn,.sendbtn:focus,.sendbtn:hover{
  border: unset;
  background-color: #152243;
}

// 按钮样式
.phonebtn,.phonebtn:focus,.phonebtn:hover{
  border: unset;
  background-color: #152243;
}
.threebox{
  width: 100%;
  // background-color: #F8F8F8;
  position: absolute;
  bottom: 130px;
  display: flex;
  padding-left: 5px;
}
.lbox{
  border: 2px solid #152243;
  border-radius: 25px;
  padding: 3px 5px;
  z-index: 101;
  cursor: pointer;
}
.lbox:hover{
  background-color: #363d4e;
  color: #fff;
}
.lbox + .lbox{
  margin-left: 5px;
}

::v-deep .el-textarea__inner{
  border: unset;
}
// 字数统计
::v-deep .el-textarea .el-input__count{
  bottom: -38px;
  right: 73px;
}
// ::v-deep .el-input .el-input__count{
//   position: absolute;
//   bottom: -48px;
//   right: 73px;
// }
.showminibox{
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #152243;
}
.wjcode{
  position: absolute;
  right: 0px;
  top: -6px;
  transform: scale(0.5);
}
.wjphone{
  position: absolute;
  top: 67px;
  right: 84px;
  width: 275px;
  height: 80px;
  padding: 10px 15px 10px 15px;
  background: #fff;
  box-shadow: 0px 0px 8px #c4c4c4;
  bottom: 160px;

  display: flex;
  align-items: center;
}

// --
.freephone{
  position: absolute;
  width: 100%;
  // bottom: 88px;
}
.close{
  position: absolute;
  top: 3px;
  right: 5px;
  transform: scale(1.5);
  cursor: pointer;
}

</style>
