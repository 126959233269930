<template>
  <div v-show="show" class="loading">
    <div class="center-container">
      <div class="container">
        <div class="txtTip">
          店铺已过期，请订阅套餐后恢复使用
        </div>

        <a-button type="primary" class="handle-btn" @click="goToStorePackage()">
          前往订阅套餐
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    el: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.stopMove()
      } else {
        this.Move()
      }
    }
  },
  methods: {
    // 停止页面滚动
    stopMove() {
      const m = function(e) {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', m, { passive: false }) // 禁止页面滑动
    },
    // 开启页面滚动
    Move() {
      const m = function(e) {
        e.preventDefault()
      }
      document.body.style.overflow = '' // 出现滚动条
      document.removeEventListener('touchmove', m, { passive: true })
    },
    goToStorePackage() {
      // $storePackage
      this.Move()
      this.$router.push({ name: 'StorePackage' })
    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.5);
}
.center-container{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 360px;
  height: 160px;
  background: #fff;
  border-radius: 4px;
  .container{
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;

  }
}

.txtTip{
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 30px;
}

.handle-btn{
  background-color: rgb(24, 144, 255);
}
</style>
