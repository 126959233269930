/**
 * @author  XuHongli
 * @date  2022/2/17 13:58
 * @version 1.0
 * @description 正则集合
 */

/**
 * @export validateURL
 * @param {String} textval Url地址
 * @description 校验URL是否合法
 */
export function validateURL(textval) {
  const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return urlregex.test(textval)
}

/* 小写字母*/
export function validateLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/* 大写字母*/
export function validateUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}
/**
 *
 * @param {String} str
 * @description 大小写字母密码
 */
export function validatAlphabets(str) {
  const reg = /(?=.*\d)(?=.*[a-zA-Z]).{8,16}/
  return reg.test(str)
}

/**
 * 验证邮箱
 * @param {String} str
 * @returns {boolean}
 * @description 校验邮箱是否合法
 */
export function validatEmail(str) {
  const reg = /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
  return reg.test(str)
}

/**
 * 验证数字+小写字母
 * @param {String} str
 * @returns {boolean}
 * @description 校验邮箱是否合法
 */
export function validatNumLetter(str) {
  const reg = /^[a-z0-9]{4,10}$/
  return reg.test(str)
}

/**
 * 验证电话号码
 * @param {String} str
 * @returns {boolean}
 * @description 校验电话号码是否合法
 */
export function isvalidPhone(str) {
  const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/
  return reg.test(str)
}

// 节流
export function throttling(fn, wait, maxTimelong) {
  var timeout = null
  var startTime = Date.parse(new Date())

  return function() {
    if (timeout !== null) clearTimeout(timeout)
    var curTime = Date.parse(new Date())
    if (curTime - startTime >= maxTimelong) {
      fn()
      startTime = curTime
    } else {
      timeout = setTimeout(fn, wait)
    }
  }
}

// 防抖
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}
