<!-- 通用官网布局 导航条 -->
<template>
  <div class="NavBar">
    <b-navbar ref="nav" toggleable="lg" type="light" class="sp">
      <b-navbar-brand href="#" @click="goHome()">
        <b-img :src="require('../../assets/img/Logo.png')" style="height: 50px;margin: 5px" />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" />

      <b-collapse id="nav-collapse" is-nav>
        <!-- 一：媒体查询控制 -->
        <div class="show-pc">
          <b-navbar-nav>
            <template v-for="(item,index) in navList">
              <template v-if="item.children.length > 0">
<!--     example           -->
<!--                <b-nav-item :key="index + 'tiktok'" href="http://cloud.vllshop.com">
                  <a href="http://cloud.vllshop.com">Tiktok云手机</a>
                </b-nav-item>-->
                <b-nav-item-dropdown :key="index" href="#" :text="$t('route.'+item.meta.title)" style="">
                  <b-dropdown-item v-for="(item,index) in item.children" :key="index" href="#" style="font-size: 1.6rem" @click="clickSubRouter(item.path)">
                    {{ $t('route.' + item.meta.title) }}
                  </b-dropdown-item>
                </b-nav-item-dropdown>
              </template>
              <template v-else>
                <b-nav-item :key="index" :active="index == Flag" :to="item.path" @click="Flag=index">
                  {{ $t('route.' + item.meta.title) }}
                </b-nav-item>
              </template>
            </template>
          </b-navbar-nav>
        </div>
        <!-- 二 -->
        <div class="show-mobile">
          <b-navbar-nav>
            <template v-for="(item,index) in navListMobile">
              <template v-if="item.children.length > 0">
                <b-nav-item-dropdown :key="index" href="#" :text="$t('route.'+item.meta.title)" style="margin-left:1rem">
                  <b-dropdown-item v-for="(item,index) in item.children" :key="index" href="#" style="font-size: 1.6rem" @click="clickSubRouter(item.path)">
                    {{ $t('route.' + item.meta.title) }}
                  </b-dropdown-item>
                </b-nav-item-dropdown>
              </template>
              <template v-else>
                <b-nav-item :key="index" href="#" @click="clickSubRouter(item.path)">
                  {{ $t('route.' + item.meta.title) }}
                </b-nav-item>
              </template>
            </template>
          </b-navbar-nav>
        </div>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <div class="user" @click="handleUser('Login')">
            <span>{{ $t('navBar.login') }}</span>
          </div>
          <div>
            <el-button type="primary" class="userbtn" @click="handleUser('Register')">
              {{ $t('navBar.tryIt') }}
            </el-button>
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/2/17 13:58
 * @version 1.0
 * @description 通用官网布局 导航条
 */

import { setLanguage } from '@/utils/cookie'

export default {
  name: 'Navbar',
  data() {
    return {
      navList: [],
      navListMobile: [],
      hoverNavItem: 0,
      FlagIn: true,
      Flag: 0
    }
  },
  watch: {
    '$route'(to, from) {
      this.Flag = this.getIndex()
    }
  },
  created() {
    this.navListMobile = this.navList = this.filterRouters(this.$router.options.routes)
    this.navList = this.filterRouters(this.$router.options.routes)
    this.navList[1].children = [] // 置空
    this.navList[2].children = [] // 置空
    console.log(this.navList)
    console.log(this.$route)
    this.Flag = this.getIndex()
    // this.Flag = this.getIndex()
  },
  mounted() {
  },
  methods: {
    getIndex() {
      for (let i = 0; i < this.navList.length; i++) {
        if (this.navList[i].path === this.$route.path || this.navList[i].redirect === this.$route.path) {
          return i
        }
      }
    },
    goHome() {
      this.$router.push({ name: 'Home' })
    },
    leaveNav() {
      this.FlagIn = false
    },
    hoverNav(index) {
      this.FlagIn = true
      this.hoverNavItem = index
    },
    filterRouters(array) {
      const a = []
      array.forEach((item, index) => {
        if (item.hidden !== true) {
          a.push(item)
        }
        if (item.children && item.hidden !== true) {
          a[index].children = this.filterRouters(item.children)
        }
      })
      return a
    },
    clickSubRouter(path) {
      this.$router.push(path)
      this.Flag = -1
    },
    handleCommand(command) {
      if (command === 'en') {
        this.$i18n.locale = 'en'
        setLanguage(command)
        window.location.reload()
      }
      if (command === 'zh') {
        this.$i18n.locale = 'zh'
        setLanguage(command)
        window.location.reload()
      }
    },
    handleUser(command) {
      if (command === 'Login') {
        this.$login()
      }
      if (command === 'Register') {
        this.$register()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-light .nav-item > a.active{
  position: relative;
}
.sp .nav-item > a.active:after{
  content: '';
  display: inline-block;
  width: 100%;
  background-color: #fff;
  position: absolute;
  bottom: -10%;
  left: 0;
  height: 4px;
}
.transp{
  background-color:rgba(250, 250, 250, 0);
}
.sp{
  background-color: rgba(0, 43, 112, 1);
}
div.NavBar{
  font-family: SourceHanSans, nunito, "Microsoft YaHei", sans-serif; ;
  font-size: 17px;
}
// user&btn
.user{
  line-height: 50px;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
}
.userbtn{
  margin: 0px 2rem 0 4rem;
  background-color: #FFA200;
  border-radius: 50px;
  border: none;
  height: 50px;
  font-size: 1.8rem;
  width: 170px;
}
.nav-item:nth-of-type(6):after{
  content: '';
  background-size: cover;
  background-image: url('/images/home/hot.png');
  position: absolute;
  width: 40px;
  top: -15px;
  z-index: 20;
  right: -10px;
  height: 25px;
  background-repeat: no-repeat;
}
.nav-item{
  margin: 0 1.4rem;
  position: relative;
  color: #fff;
  z-index: 22;
  >a{
    color: #141414 !important;
  }
}
::v-deep .navbar-light .navbar-nav .nav-link{
  color: #fff !important;
}
.show-pc{
  display: unset;
}
.show-mobile{
  display: none;
}

.NavBar{
  font-family: "nunito,SourceHanSans";
  font-size: 1.8rem;
}
@media (max-width: 991px) {
  .form-inline{
    >.btn{
      display: none;
    }
  }
  // 隐藏pc导航，显示mobile导航
  .show-pc{
    display: none;
  }
  .show-mobile{
    display: unset;
  }
  ::v-deep .dropdown-item{
    opacity: 0.6;
    text-align: center;
  }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
  .nav-item {
    margin: 0 0.8rem;

    > a {
      color: #141414 !important;
    }
  }
}

@media only screen and (min-width:1920px) and (max-width:2560px) {
  .navbar-light{
    padding:  14px 140px;
  }
}
</style>
