<template>
  <el-menu
    :default-active="getRoutePath()"
    class="menu"
    :collapse="isCollapse"
    @open="handleOpen"
    @close="handleClose"
  >
    <router-link to="/selection/home">
      <el-menu-item index="/selection/home">
        <i class="el-icon-s-home" />
        <span slot="title">首页</span>
      </el-menu-item>
    </router-link>
    <router-link to="/findproduct/index">
      <el-menu-item index="/findproduct/index">
        <i class="el-icon-search" />
        <span slot="title">发现商品</span>
      </el-menu-item>
    </router-link>
    <el-submenu index="3">
      <template slot="title">
        <i class="el-icon-s-order" />
        <span slot="title">商品管理</span>
      </template>
      <router-link to="/gallery/index">
        <el-menu-item index="/gallery/index">
          已上架
        </el-menu-item>
      </router-link>
      <router-link to="/favorite/index">
        <el-menu-item index="/favorite/index">
          已收藏
        </el-menu-item>
      </router-link>
      <router-link to="/purchased/index">
        <el-menu-item index="/purchased/index">
          订单列表
        </el-menu-item>
      </router-link>
    </el-submenu>
    <!-- <router-link to="/detailproductorder/index">
      <el-menu-item index="/detailproductorder/index">
        <i class="el-icon-document" />
        <span slot="title">订单</span>
      </el-menu-item>
    </router-link>
    <router-link to="/selection/index">
      <el-menu-item index="/selection/index">
        <i class="el-icon-setting" />
        <span slot="title">阿里巴巴</span>
      </el-menu-item>
    </router-link> -->
  </el-menu>
</template>

<script>
export default {
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    getRoutePath() {
      const menuPath = this.$route.path
      return menuPath
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  text-align: left;
}
.menu:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
</style>
