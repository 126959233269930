/**
 * @author  XuHongli
 * @date  2022/2/17 13:58
 * @version 1.0
 * @description Svg 通用组件
 */

import Vue from 'vue'
import SvgIcon from '@/components/SvgIcon/' // svg组件

// register globally
Vue.component('svg-icon', SvgIcon)

const requireAll = (requireContext) =>
  requireContext.keys().map(requireContext)
const req = require.context('./svg', false, /\.svg$/)
requireAll(req)
