<template>
  <el-container style="height: 100vh;">
    <el-aside style="background-color: #002157;;width: unset;">
      <div v-if="!isCollapse" class="header">
        <div>
          <img src="/images/shopmanagement/shopManagementLogo.png" alt="logo" style="width: 180px;padding: 30px;">
        </div>
      </div>
      <Sider :is-collapse="isCollapse" />
    </el-aside>

    <el-container>
      <el-header
        style="text-align: right; font-size: 12px; padding: 0; height: unset"
      >
        <heard :is-collapse="isCollapse" />
      </el-header>
      <el-main style="padding: 0;position: relative;">
        <shopDiaableVue :show="isMask" />
        <keep-alive include="Searchproduct">
          <router-view />
        </keep-alive>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import heard from '@/productlayout/components/heard.vue'
import Sider from 'views/shop/Layout/Sider'
import shopDiaableVue from '../components/shop-disable.vue'
export default {
  components: {
    Sider,
    heard,
    shopDiaableVue
  },
  provide() {
    return {
      collapseMenu: this.collapseMenu,
      mask: this.mask // 遮罩层
    }
  },
  data() {
    return {
      isCollapse: false,
      isMask: false
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    // 屏幕检测
    init() {
      this.setScreen()
      window.onresize = () => {
        setTimeout(() => {
          this.setScreen()
        }, 0)
      }
    },
    setScreen() {
      const screenNumber = this.getScreen()
      this.isCollapse = screenNumber !== 3
    },

    getScreen: function() {
      var width = document.body.clientWidth
      if (width >= 1200) {
        return 3 // 大屏幕
      } else if (width >= 992) {
        return 2 // 中屏幕
      } else if (width >= 768) {
        return 1 // 小屏幕
      } else {
        return 0 // 超小屏幕
      }
    },
    collapseMenu() {
      this.isCollapse = !this.isCollapse
    },
    mask(isMask) {
      this.isMask = isMask
    }
  }
}
</script>
<style lang="scss" scoped>

.el-header {
  background-color: #b3c0d1;
  color: #333;
}
.el-aside {
  color: #333;
}
.menu {
  text-align: left;
}

::v-deep .el-menu{
  border-right: unset;
}

// LOGO
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 91px;
  text-align: center;
  line-height: 91px;
  background-color: #002157;
}
</style>
