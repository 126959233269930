import { getToken, getusernameToken, setusernameToken } from '../utils/cookie'
import shopLayout from '../views/shop/Layout/shopLayout'
// import store from '../store'

const shopRouter = {
  path: '/shop',
  redirect: '/shop/shopManagement',
  component: shopLayout,
  hidden: true,
  children: [
    {
      title: '店铺管理',
      name: 'shopManagement',
      path: '/shop/shopManagement',
      chooseIcon: '/images/shopmanagement/shop_select.png',
      icon: '/images/shopmanagement/shop.png',
      component: () => import('@/views/shop/shopManagement'),
      beforeEnter: (to, from, next) => {
        // console.log(to, '表单')
        // 存在参数的时候
        // console.log(store)
        if (to.query.user) {
          setusernameToken(to.query.user)
          if (to.name === 'shopManagement' && !getToken(to.query.user)) next({ path: '/register', query: { registerType: 'login' }})
          else next()
        } else {
          if (to.name === 'shopManagement' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
          else next()
        }
      },
      hidden: true
    },
    {
      title: '账户',
      chooseIcon: '/images/shopmanagement/account_select.png',
      icon: '/images/shopmanagement/account.png',
      name: 'accountInfo',
      path: '/shop/accountInfo',
      component: () => import('@/views/shop/accountInfo/accountInfo'),
      beforeEnter: shopBeforeEnter,
      hidden: true
    },
    {
      title: '银行卡',
      name: 'credit',
      path: '/shop/credit',
      admin: true,
      show: true,
      component: () => import('@/views/shop/credit/credit'),
      beforeEnter: shopBeforeEnter,
      hidden: true
    },
    // {
    //   title: '应用',
    //   chooseIcon: '/images/shopmanagement/application_select.png',
    //   icon: '/images/shopmanagement/application.png',
    //   name: 'application',
    //   path: '/shop/application',
    //   component: () => import('@/views/shop/application/application'),
    //   beforeEnter: shopBeforeEnter,
    //   hidden: true
    // },
    {
      title: '订单管理',
      chooseIcon: '/images/shopmanagement/bill_select.png',
      icon: '/images/shopmanagement/bill.png',
      name: 'orderManagement',
      path: '/shop/orderManagement',
      admin: true,
      component: () => import('@/views/shop/orderManagement'),
      beforeEnter: shopBeforeEnter,
      hidden: true
    }
    // {
    //   title: '用户管理',
    //   chooseIcon: '/images/shopmanagement/user_select.png',
    //   icon: '/images/shopmanagement/user.png',
    //   name: 'userManagement',
    //   path: '/shop/userManagement',
    //   admin: true,
    //   component: () => import('@/views/shop/userManagement'),
    //   beforeEnter: shopBeforeEnter,
    //   hidden: true
    // }
  ]
}

function shopBeforeEnter(to, form, next) {
  if (to.name === 'shop' && !getToken(getusernameToken())) next({ path: '/register', query: { registerType: 'login' }})
  else next()
}

export default shopRouter
