<template>
  <div id="app">
    <template v-if="true">
      <transition mode="out-in">
        <router-view />
      </transition>
    </template>
    <template v-else>
      <router-view />
    </template>
  </div>
</template>
<script>
import { getusernameToken } from './utils/cookie'

export default {
  data() {
    return {
      onlyone: 'fade-transform',
      listener: '', /** 监听器 */
      username: ''
    }
  },
  watch: { // 使用watch 监听$router的变化
    $route(to, from) {
      if (from.path === '/') {
        // 设置动画名称
        this.onlyone = 'fade-transform'
      } else {
        this.onlyone = ''
      }
    }
  },
  created() {
    /** 监听Token */
    this.username = getusernameToken()
    // this.listener = setInterval(() => {
    //   const username = getusernameToken()
    //   if (this.username !== username && this.$route.path !== '/register') {
    //     console.log(this.$route.path)
    //     this.username = username
    //     this.$router.go(0)
    //     console.log('cookieChange')
    //   }
    // }, 500)
  }
}
</script>

<style lang="scss">
#app {
  font-family: nunito, SourceHanSans, "Microsoft YaHei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.fade-enter {
  opacity:0;
}
.fade-leave{
  opacity:1;
}
.fade-enter-active{
  transition:opacity .5s;
}
.fade-leave-active{
  opacity:0;
  transition:opacity .5s;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.el-cascader__dropdown {
  height: 200px;
}
.el-cascader-panel {
  height: 200px;
}
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 1s cubic-bezier(.88,.55,1,.77), border 0s
}

.fade-transform-enter {
  opacity: 0;
}

.fade-transform-leave-to {
  opacity: 0;
}

</style>
