/**
 * @author  XuHongli
 * @date  2022/2/17 13:58
 * @version 1.0
 * @description Request 无需Token
 */

import axios from 'axios'
import { Notification } from 'element-ui'

const service = axios.create({
  // baseURL: 'http://vllshop.jingdandan.com/shop-line/',
  baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_URL : '/api', // api 的 base_url
  timeout: 1200000 // 请求超时时间 ms 2分钟
})
// request拦截器
service.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const code = response.status
    if (code < 200 || code > 300) {
      Notification.error({
        title: response.message
      })
      return Promise.reject('error')
    } else {
      return response.data
    }
  }
)

export default service
